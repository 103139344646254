.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}





html, body , header{
    max-width: 100vw;
    overflow-x: hidden;
}

header {
  background-color: #a02d2d;
  grid-area: header;
  font-family: 'Roboto', sans-serif;
  margin: 0px;
  padding: 5px;
  display: flex;
  width: 100vw;
  flex-wrap: wrap;
  position: fixed;
}

/*Alle anchor (link) haben Farbe ihres Parents und keine Deco */
a {
  color: inherit;
  text-decoration: none;
}

.container {
  display: grid;
  min-height: 100vh;
  grid-template-rows: auto 1fr 60px;
  grid-template-columns: 5vw 30vw 30vw 30vw 5vw;
  grid-template-areas:
    "header header header header header"
    "spaceLeft content content content spaceRight"
    "footer footer footer footer footer"
}

.websiteName {
  max-width: 15%;
  min-width: 15%;
  margin: 0%;
  text-align: left;
}

.websiteNameAsLink {
 margin: 0%;
 width: 100%;
 margin-left: 2%;
}

.searchHeader {
  max-width: 85%;
  min-width: 85%;
}

.spaceLeft {
  background: white;
  grid-area: spaceLeft;
}

body {
  width: 100vw;
  margin: 0;
  padding: 0;
  background-color: #cfcfcf;
}

.tile {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  background-color: #f8ecd9;
  border-color: #a02d2d;
  border-style: solid;
  min-width: 70vw;
  max-width: 70vw;
  height: 100%;
  flex: 1;
  margin-top: 2rem;
  box-shadow: 5px 5px 5px grey;
  margin: 1rem;
}

.DetailPage {
  position: fixed;
  width: 70vw;
  height: 70vh;
  top: 50%;
  left: 50%;
  margin-top: -35vh; /* Negative half of height. */
  margin-left: -35vw; /* Negative half of width. */
  z-index: 100;
  background-color: #f8ecd9;
  border-color: #a02d2d;
  border-style: solid;
}

.blurFilter {
  opacity: 0.5;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 50%;
  left: 50%;
  margin-top: -50vh; /* Negative half of height. */
  margin-left: -50vw; /* Negative half of width. */
  z-index: 99;
  background-color: gray;
}

.detailContainer{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}

.wineHeadlineDetail{
  width: 100%;
  height: 10%;
  margin: 0%;
  text-align: center;
}
.winePicContainerDetail{
  width: 33.3%;
  height: 50%;
  margin: 0%;
  text-align: center;
}
.weinflascheDetail{
  max-height: 100%;
  max-width: 100%;
}

.categoryContainer_Detail{
  width: 33%;
  height: 50%;
  margin: 0%;
}

.categoryContainer_Detail_addWine{
  width: 66%;
  height: 50%;
  margin: 0%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.imageUpload_Detail_addWine{
  width: 90%;
  text-align: center;
}
.previewImage_Detail_addWine{
  max-width: 99%;
  max-height: 80%;
  margin: 0%;
}

.filter_Detail_addWine{
  width: 35%;
  margin: 0%;
}

.googleMapsMap_Container_Details{
  width: 33%;
  height: 50%;
  margin: 0%
}

.googleMapsMap_Details {
  width: 100%;
  height: 100%;
}

.wineDescriptionDetail{
  width: 100%;
  height: 28%;
  margin-top: 1%;
  margin-bottom: 1%;
  margin-left: 4%;
  margin-right: 4%;
}

.buttonContainerDetail{
  width: 100%;
  height: 10%;
  display: flex;
  wrap: nowrap;
  justify-content: space-evenly;
}
.detailButton{
  width: 28%;
  height: 70%;
}

.suchenEingabefeldHeader {
  font-size: 100%;
  background: #f1f1f1;
  border: 2px solid black;
  padding: 0%;
  height: 70%;
  width: 30%;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

textarea.wineDescriptionDetail {
  font-size: 15px;
}



@media (min-width: 1921px) {
  .tile {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    background-color: #f8ecd9;
    border-style: solid;
    min-width: 18vw;
    max-width: 22vw;
    height: 100%;
    flex: 1;
    margin-top: 2rem;
    box-shadow: 5px 5px 5px grey;
    margin: 1rem;
  }

  .filter {
    min-width: 18vw;
    max-width: 22vw;
    margin-top: .8rem;
    text-align: center;
  }

  .filterButton {
    min-width: 30vw;
    max-width: 38vw;
    font-family: sans-serif;
    font-weight: 700;
    margin: 1rem;
    background: #e5e5e5;
    color: black;
    font-size: 100%;
    border: 2px solid black;
    cursor: pointer;
  }

  .wineDescriptionDetail{
    width: 100%;
    height: 28%;
    margin-top: 0.5%;
    margin-bottom: 0.5%;
    margin-left: 4%;
    margin-right: 4%;
  }
}

@media (min-width: 1025px) and (max-width: 1920px) {
  .tile {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    background-color: #f8ecd9;
    border-style: solid;
    min-width: 23vw;
    max-width: 28vw;
    height: 100%;
    flex: 1;
    margin-top: 2rem;
    box-shadow: 5px 5px 5px grey;
    margin: 1rem;
  }

  .filter {
    min-width: 23vw;
    max-width: 28vw;
    margin-top: .8rem;
    text-align: center;
  }

  .filterButton {
    min-width: 23vw;
    max-width: 28vw;
    font-family: sans-serif;
    font-weight: 700;
    margin: 1rem;
    background: #e5e5e5;
    color: black;
    font-size: 100%;
    border: 2px solid black;
    cursor: pointer;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .tile {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    background-color: #f8ecd9;
    border-style: solid;
    min-width: 40vw;
    max-width: 45vw;
    height: 100%;
    flex: 1;
    margin-top: 2rem;
    box-shadow: 5px 5px 5px grey;
    margin: 1rem;
  }

  .filter {
    min-width: 40vw;
    max-width: 45vw;
    margin-top: .8rem;
    text-align: center;
  }

  .filterButton {
    min-width: 18vw;
    max-width: 22vw;
    font-family: sans-serif;
    font-weight: 700;
    margin: 1rem;
    background: #e5e5e5;
    color: black;
    font-size: 100%;
    border: 2px solid black;
    cursor: pointer;
  }

  .websiteName{
    flex: 1;
    min-width: 45vw;
    max-width: 50vw;
  }
  .searchHeader {
    flex: 1;
    min-width: 45vw;
    max-width: 50vw;
  }
  .loginHeader {
    flex: 2;
    min-width: 90vw;
    max-height: 100vw;
  }

  .filterblock {
    margin-top: 5% !important;
  }

  .ABSENDENinfo {
    width: 50% !important;
}
}

@media (min-width: 320px) and (max-width: 767px) {
  .tile {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    background-color: #f8ecd9;
    border-style: solid;
    min-width: 80vw;
    max-width: 80vw;
    height: 100%;
    flex: 1;
    margin-top: 2rem;
    box-shadow: 5px 5px 5px grey;
    margin: 1rem;
  }

  .filter {
    min-width: 40vw;
    max-width: 45vw;
    margin-top: .8rem;
    text-align: center;
  }

  .filterButton {
    min-width: 35vw;
    max-width: 40vw;
    font-family: sans-serif;
    font-weight: 700;
    margin: 1rem;
    background: #e5e5e5;
    color: black;
    font-size: 100%;
    border: 2px solid black;
    cursor: pointer;
  }

  .websiteName{
    flex: 1;
    min-width: 90vw;
    max-width: 100vw;
  }
  .searchHeader {
    flex: 1;
    min-width: 90vw;
    max-width: 100vw;
  }

  .DetailPage {
    width: 86vw;
    height: 90vh;
    margin-top: -45vh;
    margin-left: -43vw;
  }

  .winePicContainerDetail {
    width: 50%;
    height: 40%;

  }
  .categoryContainer_Detail {
      width: 50%;
      height: 40%;
      margin: 0%;
  }

  .googleMapsMap_Container_Details {
    width: 45%;
    height: 40%;
    margin: 1%;
  }

  .wineDescriptionDetail {
    width: 45%;
    height: 40%;
    margin-top: 0%;
    margin-bottom: 0%;
    margin-left: 4%;
    margin-right: 4%;
    text-align: center;
  }

  .detailButton {
    margin-top: 5%;
    height: 50%;
  }

  .categoryContainer_Detail_addWine {
    width: 100%;
    height: 45%;
  }

  textarea.wineDescriptionDetail {
    width: 100%;
    height: 30%;
  }

  #editPic.winePicContainerDetail {
    display: none;
  }

  .suchenEingabefeldHeader {
    width: 65%;
  }

  .filterblock {
    margin-top: 10vh !important;
  }

  .infoText {
    font-size: small;
  }

  .infoHeadline {
    margin-bottom: 2rem !important;
  }

  .ABSENDENinfo {
    width: 70% !important;
    height: 18%;
}


}


.content_Startseite {
  background: #f1ede9;
  grid-area: content;
}

.content_Naturweinseite {
  background: #f1ede9;
  grid-area: content;
}

.content_Detailansicht {
  background: #f1ede9;
  grid-area: content;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spaceRight {
  background: white;
  grid-area: spaceRight;
}

.filterblock {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 4%;
}

.filter {
  width: 70vw;
  margin-top: .8rem;
  text-align: center;
}

.labelFilter {
  margin: 0rem;
  font-size: 15px;
  font-family: sans-serif;
  font-weight: 500;
  width: 100%;
}

.auswahlFilter {
  display: block;
  font-size: 15px;
  font-family: sans-serif;
  font-weight: 700;
  color: #444;
  line-height: 1.3;
  width: 100%;
  max-width: 100%;
  padding: 0.2em;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, .04);
}

.filterbuttonBlock {
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  margin-top: 1.2rem;
  margin-bottom: 0.2rem;
}

.filterButton {
  width: 16%;
  font-family: sans-serif;
  font-weight: 700;
  margin: 1rem;
  background: #e5e5e5;
  color: black;
  font-size: 100%;
  border: 2px solid black;
  cursor: pointer;
}

.tileBlock {
  display: flex;
  border: 1px solid;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.weinflascheContainer {
  width: 50%;
  height: 100%;
}

.weinflasche {
  width: 100%;
  height: 100%;
}

.weinBeschreibungContainer {
  width: 50%;
}

.weinBeschreibungHeadline {
  font-family: 'Roboto', sans-serif;
  width: 100%;
  text-align: left;
  margin-top: 20%;
  margin-bottom: 20%;
  text-align: center;
}

.weinBeschreibungHeadline a {
  text-decoration: none;
  color: black;
}

.weinBeschreibungText {
  font-family: 'Roboto', sans-serif;
  width: 100%;
  text-align: left;
  margin-top: 10%;
}

.mixedTopBlock {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  border-color: #a02d2d;
  border-style: solid;
  margin-bottom: 2%;
  margin-top: 2%;
}

.weinbild_Container_Details {
  width: 33.3%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  text-align: center;
}

.weinbild_Details {
  max-width: 100%;
  max-height: 450px;
}

.weinattribute_Container_Details {
  width: 33.3%;
  max-height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}

.weinattribute_innerContainer_Details {
  width: 50%;
  margin: 0px;
  justify-content: center;
}

.weinattribut_Details {
  font-family: 'Roboto', sans-serif;
  font-size: 130%;
  width: 100%;
  position: relative;
  top: 50%;
  margin: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}



.beschreibungstext_Container_Details {
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  text-align: left;
}

.beschreibungstext_Details {
  font-family: 'Roboto', sans-serif;
  font-size: 110%;
  max-width: 100%;
  max-height: 100%;
  margin-left: 2%;
  margin-right: 2%;
}

.contentNaturwein {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    align-content: center;
  }

  .imgNaturwein {
    float: left;
  }

  .categoryContainer{
    display: flex;
    flex-wrap: wrap;
    text-align: left;

  }

  .categoryTag{
    flex: auto;
    display: inline-block;
    width: max-content;
    background-color: #fefefe;
    border-radius: 20px;
    text-align: center;
    margin-right: 5%;
    margin-bottom: 3%;
    margin-top: 3%;
    padding: 2%;
  }

  .categoryTagDynamic{
    flex: auto;
    display: inline-block;
    width: max-content;
    background-color: #ff9999;
    border-radius: 20px;
    text-align: center;
    margin-right: 5%;
    margin-bottom: 3%;
    margin-top: 3%;
    padding: 2%;
  }

  .addButton{
    height: 40px;
    width: 40px;
    border-radius: 100%;
    color: white;
    box-shadow: 2px 2px 2px grey;
    background-color: #a02d2d;
    font-size: 120%;
    border: none;
    position: fixed;
    right: 0.5%;
    bottom: 3%;
    cursor: pointer;
  }


  footer {
    background: #a02d2d;
    grid-area: footer;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .impressumFooterContainer {
    margin: 0%;
    width: 100%;
    height: 50%;
  }

  .impressumFooter {
    width: 100%;
    position: relative;
    top: 50%;
    margin: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .linksFooterContainer {
    margin: 0%;
    width: 100%;
    height: 50%;
    max-height: 50%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .linkFooter {
    margin-left: 1%;
    margin-right: 1%;
    width: 5%;
    flex: 1
  }

  .NaturweinInfo a {
    margin-top: 0;
    width: 500px;
  }

  .infoMainblock1{
    width: 49%;
    height: 40%;
    margin: 0%;
    margin-left: 1%;

  }

  .infoMainblock2{
    width: 49%;
    height: 40%;
    margin: 0%;
    margin-left: 1%;
    display: flex;
    flex-wrap: wrap;
    align-items: left;
  }

  .TAinfo{
    width: 98%;
    height: 40%;
  }

  .ABSENDENinfo{
    width: 20%;
    height: 18%;
  }

  .infoMainblock3{
    width: 99%;
    height: 40%;
    margin: 0%;
    margin-left: 1%;
  }

  .infoHeadblock{
    width: 100%;
    height: 10%;
    margin: 0%;
    text-align: center;
  }

  .infoBottomblock{
    width: 100%;
    height: 10%;
    display: flex;
    wrap: nowrap;
    justify-content: space-evenly;
  }

  .infoText{
    text-align: left;
  }

  .infoHeadline{
    width: 100%;
    height: 10%;
    margin: 0%;
    text-align: left;
  }
